export default {
  couponViewItem: [
    {
      prop: 'name'
    }, 
    {
      prop: 'use_type'
    },
    {
      prop: 'price',
      unit: 'S$'
    },
    {
      prop: 'count'
    },
    {
      prop: 'valid_days'
    },
    {
      prop: 'expire_date',
      date: true,
      format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
    },
    {
      prop: 'coupon_code'
    }
  ],
  orderViewItem: [
    // {
    // prop: 'status',
    // back: true
    // }, 
  {
    prop: 'client'
  }, {
    prop: 'name'
  },  {
    prop: 'payment_channel'
  }, {
    prop: 'created_at',
    // date: true,
    // format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'paid_at'
  }],
  CustomerViewItem: [{
    prop: 'user_id'
  }, {
    prop: 'nickname'
  }, {
    prop: 'phone_number'
  }],
  tableDataViewItem: [{
    prop: 'image',
    img: true,
    label: '',
    width: '50'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'shop_item_stock',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'preferences',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'sku',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'count',
    label: '',
    // width: '40',
    Object: 'value'
  }, {
    prop: 'price',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '40'
  }]
}
